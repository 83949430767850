'use strict';

(function () {
  const BUTTON = document.querySelector('.js-change-button');
  const INPUTS = document.querySelector('.js-inputs-change');

  function toggleAttribute(elms, attr) {
    elms.querySelectorAll('.js-disabled-input').forEach((c) => {
      c.toggleAttribute(attr);
    });
  }

  function buttonClickHandler(evt) {
    evt.preventDefault();

    let target = evt.currentTarget;
    let targetAltText = target.dataset.altText;

    toggleAttribute(INPUTS, 'disabled');

    target.dataset.altText = target.textContent;
    target.textContent = targetAltText;

    target.removeEventListener(`click`, buttonClickHandler);
    target.addEventListener(`click`, buttonAnotherClick);
  }

  function buttonAnotherClick(evt) {
    evt.preventDefault();

    let target = evt.currentTarget;
    let targetAltText = target.dataset.altText;

    toggleAttribute(INPUTS, 'disabled');

    target.removeEventListener(`click`, buttonAnotherClick);
    target.addEventListener(`click`, buttonClickHandler);

    target.dataset.altText = target.textContent;
    target.textContent = targetAltText;
  }

  if (BUTTON) {
    BUTTON.addEventListener(`click`, buttonClickHandler);
  }
})();
