'use strict';

(function () {
  // Модальное окно
  (function () {
    const
      MODAL_BUTTON_CLOSE_CLASS_NAME = 'js-modal-close',
      TRANSITION_TIME = 0.4;

    let alexModal = function (modal) {
      let
        buttonModalShow = modal,
        modalWindow = document.querySelector(buttonModalShow.dataset.id),
        buttonModalHide = modalWindow.querySelectorAll('.' + MODAL_BUTTON_CLOSE_CLASS_NAME),
        body = document.body;

      function openModal() {
        let scrollBarWidth = window.utils.getScrollbarWidth();

        body.classList.add('modal-open');

        document.documentElement.style.setProperty('--scroll-width', scrollBarWidth + 'px');
        modalWindow.style.paddingRight = scrollBarWidth + 'px';

        modalWindow.style.display = 'block';
        setTimeout(function () {
          modalWindow.classList.remove('modal-hidden');
        }, 100);

        window.overlay.add(100);

        document.addEventListener('keydown', documentKeyDownHandler);
        modalWindow.addEventListener('click', modalWindowClickHandler);

        // Доступность для модалк
        modalWindow.removeAttribute('aria-hidden');

        window.focusTrap.setFocusTrap(modalWindow);

      }

      function closeModal() {
        modalWindow.classList.add('modal-hidden');

        // Вешаю отсчёт времени для отработки CSS анимации
        setTimeout(function () {

          body.classList.remove('modal-open');
          document.documentElement.style.setProperty('--scroll-width', '0');

          modalWindow.style.display = 'none';
          modalWindow.style.paddingRight = '0';
        }, TRANSITION_TIME * 1000);

        window.overlay.remove(TRANSITION_TIME * 1000);

        // Доступность для модалк
        modalWindow.setAttribute('aria-hidden', 'true');

        // Убираю все события после закрытия модалки
        if (buttonModalHide) {
          buttonModalHide.forEach(function (current) {
            current.removeEventListener('click', closeModal);
          });
        }
        modalWindow.removeEventListener('click', modalWindowClickHandler);
        document.removeEventListener('keydown', documentKeyDownHandler);

        window.focusTrap.setFocusTrap(modalWindow);

        buttonModalShow.focus();
      }

      function documentKeyDownHandler(evt) {
        if (evt.key === window.utils.keyEscape && !evt.target.matches('input, textarea, select')) {
          closeModal();
          // console.log(evt);
        }
      }

      function modalWindowClickHandler(evt) {
        if (evt.target === modalWindow) {
          closeModal();
        }
      }

      buttonModalShow.addEventListener('click', function (evt) {
        evt.preventDefault();
        openModal();

        // После клика по кнопке открытия модалки
        // добавляю событие клика по кнопке закрытия
        if (buttonModalHide) {
          buttonModalHide.forEach(function (current) {
            current.addEventListener('click', closeModal);
          });
        }
      });

      buttonModalShow.addEventListener('keydown', function (evt) {
        if (evt.key === window.utils.keyEnter && !buttonModalShow.matches('button, a')) {
          evt.preventDefault();
          openModal();
          if (buttonModalHide) {
            buttonModalHide.forEach(function (current) {
              current.addEventListener('click', closeModal);
            });
          }
        }
      });

      if (modalWindow.classList.contains('open')) {
        openModal();

        // После клика по кнопке открытия модалки
        // добавляю событие клика по кнопке закрытия
        if (buttonModalHide) {
          buttonModalHide.forEach(function (current) {
            current.addEventListener('click', closeModal);
          });
        }
      }
    };

    window.modal = {
      alexModal: alexModal
    }

  })();

})();
