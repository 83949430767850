'use strict';

(function () {
  window.lang = {
    ru: {
      days: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
      daysShort: ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Суб"],
      daysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
      months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
      monthsShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
      today: "Сегодня",
      clear: "Очистить",
      format: "dd.mm.yyyy",
      weekStart: 1,
      monthsTitle: "Месяцы"
    },
    uz: {
      days: ["Yakshanba", "Dushanba", "Seshanba", "Chorshanba", "Payshanba", "Juma", "Shanba"],
      daysShort: ["Yak", "Du", "Se", "Chor", "Pay", "Ju", "Sha"],
      daysMin: ["Ya", "Du", "Se", "Cho", "Pa", "Ju", "Sha"],
      months: ["Yanvar", "Fevral", "Mart", "Aprel", "May", "Iyun", "Iyul", "Avgust", "Sentabr", "Oktabr", "Noyabr", "Dekabr"],
      monthsShort: ["Yan", "Fev", "Mar", "Apr", "May", "Iyn", "Iyl", "Avg", "Sen", "Okt", "Noy", "Dek"],
      today: "Bugun",
      clear: "O'chirish",
      format: "dd.mm.yyyy",
      weekStart: 1,
      monthsTitle: "Oylar"
    }
  };

  let inputDate = $('.input-datepicker');

  if (inputDate.length) {


    let input = $('.input-datepicker input');
    let defaultText = input.val();
    let currentLang = 'ru';

    !function (a) {
      a.fn.datepicker.dates["uz-latn"] = window.lang.uz;
    }(jQuery);

    !function (a) {
      a.fn.datepicker.dates.ru = window.lang.ru;
    }(jQuery);

    inputDate.datepicker({
      format: "dd MM yyyy",
      todayHighlight: true,
      endDate: "today",
      language: currentLang,
      autoclose: true,
      clearBtn: true
    }).on('changeDate', function (e) {
      var table = $('#myTabContent .active .js-data-table-rows').DataTable();

      let d = new Date(e.date);
      let m = d.getMonth() + 1;

      let
        month = (m < 10) ? "0" + m : m,
        day = (d.getDate() < 10) ? "0" + d.getDate() : d.getDate();

      let date = (e.date) ? month + "." + day + "." + d.getFullYear() : "";

      table.columns(1).search(date).draw();
      $(this).attr('data-fucking-date', date);

      if (!date) {
        setTimeout(() => {
          input.val(defaultText)
        }, 100);
      }
    })
  }
})();
