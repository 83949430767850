'use strict';

(function () {

  // Добавление затемнения под модалки
  (function () {
    const OVERLAY_CLASS_NAME = 'modal-overlay';

    let createOverModal = window.utils.createDivWithClass(OVERLAY_CLASS_NAME);

    let addOverlayWindow = function (time) {
      document.body.appendChild(createOverModal);

      let modalOverlay = document.querySelector('.' + OVERLAY_CLASS_NAME);

      setTimeout(function () {
        modalOverlay.classList.add('this-show');
      }, time)
    };

    let removeOverlayWindow = function (time) {
      let
        duration = (time) ? time : 0,
        modalOverlay = document.querySelector('.' + OVERLAY_CLASS_NAME);

      modalOverlay.classList.remove('this-show');

      setTimeout(function () {
        modalOverlay.remove();
      }, duration)
    };

    window.overlay = {
      add: addOverlayWindow,
      remove: removeOverlayWindow
    }

  })();
})();
