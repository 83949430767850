'use strict';

(function () {
  // Зацикливание фокуса для доступности в модалках
  (function () {
    const
      MODAL_ELEMENT_FOCUSED = window.utils.focusedElement,
      MODAL_FOCUS_TRAP_CLASS_NAME = 'js-trap-focus';

    function setFocusTrap(focusWrap) {
      let
        focusedElements = focusWrap.querySelectorAll(MODAL_ELEMENT_FOCUSED),
        firstFocusElement = focusedElements[0],
        lastFocusElement = focusedElements[focusedElements.length - 1],
        defaultFocusElements = focusWrap.querySelectorAll('.' + MODAL_FOCUS_TRAP_CLASS_NAME);

      if (defaultFocusElements.length === 0 && focusedElements.length !== 0) {
        let
          createFocusTrap = window.utils.createDivWithClass(MODAL_FOCUS_TRAP_CLASS_NAME),
          cloneCreateFocusTrap = createFocusTrap.cloneNode();

        cloneCreateFocusTrap.setAttribute('tabindex', '0');
        createFocusTrap.setAttribute('tabindex', '0');

        focusWrap.appendChild(createFocusTrap);
        focusWrap.insertAdjacentElement('afterbegin', cloneCreateFocusTrap);

        // Зацикливание фокуса внутри модалки
        let focusTraps = focusWrap.children;

        if (!lastFocusElement) {
          lastFocusElement = focusTraps[focusTraps.length - 1];
        }
        if (!firstFocusElement) {
          firstFocusElement = focusTraps[0];
        }

        firstFocusElement.focus();

        focusTraps[focusTraps.length - 1].addEventListener('focus', function () {
          firstFocusElement.focus();
        });

        focusTraps[0].addEventListener('focus', function () {
          lastFocusElement.focus();
        });
      } else {
        defaultFocusElements.forEach(function (current) {
          current.remove();
        })
      }

    }

    window.focusTrap = {
      setFocusTrap: setFocusTrap
    }

  })();
})();
