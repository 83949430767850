// Убирает фокус с элементов если активна мышка
(function () {
  const bodyClickHandler = () => {
    document.body.classList.add('using-mouse');
  };

  const bodyKeyDownHandler = (event) => {
    if (event.keyCode === 9) {
      document.body.classList.remove('using-mouse');
    }
  };

  function addBodyEvents () {
    // Let the document know when the mouse is being used
    document.body.addEventListener('mousedown', bodyClickHandler);

    // Re-enable focus styling when Tab is pressed
    document.body.addEventListener('keydown', bodyKeyDownHandler);
  }

  function removeBodyEvents() {
    document.body.removeEventListener('mousedown', bodyClickHandler);
    document.body.removeEventListener('keydown', bodyKeyDownHandler);
  }

  window.usingMouse = {
    add: addBodyEvents,
    remove: removeBodyEvents,
  }

})();
